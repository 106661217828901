
.MuiListItem-root {
    margin-top: 0px !important;

}

 #CloseLeftSidebarIcon {
    cursor: pointer;
    position:relative;
    float: right;
    right: 14px;
    color: rgb(149, 156, 169);
    top: 4px;
}

#OpenLeftSidebarIcon {
    cursor: pointer;
    margin-right: 10px;
}

/* 
#197c8b - teal 2
#2c617d - teal
#111827 - dark

.MuiTypography-root.MuiTypography-body1 {
    color: #2c617d
}
    */