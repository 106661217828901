/* 

https://ej2.syncfusion.com/react/documentation/appearance/theme-studio

https://support.syncfusion.com/kb/search?searchKey=How+do+I+customize+the+color+of+a+Syncfusion+react+theme%3F

https://ej2.syncfusion.com/react/documentation/appearance/theme#syncfusion-material-theme 
https://support.syncfusion.com/kb/article/4142/how-to-override-syncfusion-themes

@import '../../../../node_modules/@syncfusion/ej2-base/styles/material.css';  
@import '../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';  
@import '../../../../node_modules/@syncfusion/ej2-calendars/styles/material.css';  
@import '../../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';  
@import '../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';  
@import '../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-notifications/styles/material.css';
@import "../../../../node_modules/@syncfusion/ej2-react-grids/styles/material.css";
*/


/* Table headers 
#3cacc7 - light teal
#2c617d - teal
#197c8b - teal 2
#111827 - dark
*/
.e-grid .e-gridheader {
    color: #197c8b;
    font-family: Inter var, Roboto, "Helvetica", Arial, sans-serif;
}

.e-input-group {
    border-width: 0 0 0px 0 !important;
}
/* #f7f7f7  197c8b*/
.e-groupdroparea {
    background-color: #f7f7f7 !important;
    transform: scale(0.8);
    border-width: 0px !important;
}

.orientationcss .e-headercelldiv {
    transform: rotate(90deg);
}


.dovaxis[type="checkbox"]:before {
    position: relative;
    display: block;
    width: 12px;
    height: 12px;
    border: 2px solid #f5efef;
    content: "";
    background: #FFF;
  }
  
  .dovaxis[type="checkbox"]:after {
    position: relative;
    display: block;
    left: 1px;
    top: -11px;
    width: 12px;
    height: 12px;
    border-width: 0px;
    border-style: solid;
    border-color: #B3B3B3 #dcddde #dcddde #B3B3B3;
    content: "";
    background-image: linear-gradient(135deg, #d4d5d7 0%, #FFF 100%);
    background-repeat: no-repeat;
    background-position: center;
  }

  .dovaxis[type="checkbox"]:checked:after {
    
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAQAAABuW59YAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAB2SURBVHjaAGkAlv8A3QDyAP0A/QD+Dam3W+kCAAD8APYAAgTVZaZCGwwA5wr0AvcA+Dh+7UX/x24AqK3Wg/8nt6w4/5q71wAAVP9g/7rTXf9n/+9N+AAAtpJa/zf/S//DhP8H/wAA4gzWj2P4lsf0JP0A/wADAHB0Ngka6UmKAAAAAElFTkSuQmCC'), linear-gradient(135deg, #B1B6BE 0%, #FFF 100%);
  }
  
  .dovaxis[type="checkbox"]:disabled:after {
    -webkit-filter: opacity(0.4);
  }

.permission-switch {
  margin-right: '100px'
}